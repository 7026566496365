import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { trans, transChoice } from 'matice';
import { ZiggyVue } from 'ziggy';
import { ModalPluginOptions, modal } from 'momentum-modal';
import * as Sentry from '@sentry/vue';
import { useRegisterSW } from 'virtual:pwa-register/vue';

import './bootstrap.ts';
import './bootstrap-color-changer.ts';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

const finishListener = () => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    new window.bootstrap.Tooltip(tooltipTriggerEl);
  });

  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(function (popoverTriggerEl) {
    new window.bootstrap.Popover(popoverTriggerEl);
  });
};

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(name, import.meta.glob('../views/pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(ZiggyVue, Ziggy)//Ok like this @eslint-ignore-line
    // .use(InstantSearch)
      .use(modal, {
        resolve: (name: string) => resolvePageComponent(name, import.meta.glob('../views/pages/**/*.vue')),
      } as ModalPluginOptions)
      .use(plugin)
      .provide('$trans', trans)
      .provide('$transChoice', transChoice);

    if(import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production') {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: import.meta.env.VITE_SENTRY_RELEASE,
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,

        integrations: [
          new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/penzugyek\.intelligens-uzletvezetes\.hu/],
          }),
          new Sentry.Replay(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    app.mount(el);
    finishListener();
  },
});

router.on('finish', finishListener);

router.on('before', () => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    const el = window.bootstrap.Tooltip.getInstance(tooltipTriggerEl);
    if (el !== null) {
      el.dispose();
    }
  });

  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(function (popoverTriggerEl) {
    const el = window.bootstrap.Popover.getInstance(popoverTriggerEl);
    if (el !== null) {
      el.dispose();
    }
  });

  const offcanvasElementList = document.querySelectorAll('.offcanvas');
  [...offcanvasElementList].map(function (offcanvasEl) {
    const el = window.bootstrap.Offcanvas.getInstance(offcanvasEl);

    if (el !== null) {
      el.hide();
    }
  });
});


/**
 * Imports the given page component from the page record.
 */
async function resolvePageComponent(name: string, pages: Record<string, any>) {
  const path = Object.keys(pages)
    .sort((a, b) => a.length - b.length)
    .find((path) => path.endsWith(`${name.replaceAll('.', '/')}.vue`));

  if (!path) {
    throw new Error(`Page not found: ${name}`);
  }

  return typeof pages[path] === 'function'
    ? await pages[path]()
    : pages[path];
}

window.addEventListener('load', () => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    new window.bootstrap.Tooltip(tooltipTriggerEl);
  });

  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(function (popoverTriggerEl) {
    new window.bootstrap.Popover(popoverTriggerEl);
  });
});

const intervalMS = 60 * 60 * 1000;

useRegisterSW({
  onRegistered(r) {
    r && setInterval(() => {
      r.update();
    }, intervalMS);
  }
});
